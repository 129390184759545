.split-panel {
  background-color: #fff;
  border-radius: 5px;
  min-height: 50px;
  overflow: hidden;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); }
  .split-panel .left-content-wrapper {
      padding: 10px 20px;
      min-width: 80px;
      max-width: 100px;
      width: 15%;
      color: white;
  }
  .split-panel .right-content-wrapper {
    padding: 10px;
    color: #656565;
    vertical-align: middle; }
    .split-panel .right-content-wrapper .text-only {
      font-size: 1.3em; }
    .split-panel .right-content-wrapper .large-title {
      font-size: 1.5em;
      font-weight: bold;
      margin-right: 7px; }
    .split-panel .right-content-wrapper .title {
      font-size: 1.3em; }
    .split-panel .right-content-wrapper .description {
      font-size: 0.9em; }
